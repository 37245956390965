import serviceBase from '@/services/serviceBase'
import { ApiBooleanResult, ApiResult } from '@/services/data'

export interface ToDoGroup {
  ToDoListId: number;
  ToDoListName: string;
  TaskDate: string;
  Total: number;
  Accomplished: number;
  IsExpanded?: boolean;
  SubItems: ToDoItem[];
}

export interface ToDoItem {
  Title: string;
  IsFinished: boolean;
  Content: string;
  ToDoItemId: number;
}

export default {
  async getToDoList (): Promise<ApiResult<ToDoGroup[]>> {
    return await serviceBase.tryPostResult<ToDoGroup[]>('/toDo/getToDoList')
  },
  async markAsFinished (data: { IsFinished: boolean; ToDoItemId: number }): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/toDo/markAsFinished', data)
  }
}
