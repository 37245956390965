












































import { Component, Vue } from 'vue-property-decorator'
import toDoService, { ToDoGroup, ToDoItem } from '@/services/toDoService'

@Component
export default class ToDoList extends Vue {
  ToDoList: ToDoGroup[] = []

  async mounted () {
    await this.loadData()
  }

  getGroupLeft (item: ToDoGroup) {
    return item.Total - item.Accomplished
  }

  goTo (group: ToDoGroup) {
    if (group.ToDoListName === 'OptCheckList') {
      this.$router.push('/home/optCheck')
    }
  }

  async markToDoItem (item: ToDoItem) {
    const res = await toDoService.markAsFinished({ IsFinished: item.IsFinished, ToDoItemId: item.ToDoItemId })
    if (res.Error) {
      item.IsFinished = !item.IsFinished
      await this.$alert(res.Error)
    } else {
      this.calcRateNumbers()
    }
  }

  calcRateNumbers () {
    this.ToDoList.forEach(p => {
      p.Total = p.SubItems.length
      let finished = 0
      p.SubItems.forEach(q => {
        if (q.IsFinished) {
          finished++
        }
      })
      p.Accomplished = finished
    })
  }

  async loadData () {
    const res = await toDoService.getToDoList()
    res.Result.forEach(p => {
      p.IsExpanded = false
    })
    this.ToDoList = res.Result
    this.calcRateNumbers()
  }
}
